import React from 'react';

// Material UI
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import styles from '../styles';
import i18n from '../../i18n';

function SearchBox({ searchText, handleSearchText, loading, classes }) {
  return (
    <div className={classes.kbSearchBox}>
      <TextField
        value={searchText}
        margin="none"
        fullWidth
        size="small"
        onChange={(event) => { handleSearchText(event.target.value); }}
        placeholder={i18n.t('knowledge_base.search')}
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: '10px',
            paddingRight: '5px'
          },
          '& input': {
            fontSize: '14px'
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon fontSize="small" sx={{ fontSize: '22px !important', color: '#bdbdbd' }}>search</Icon>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => { handleSearchText(''); }}
                style={{ width: 25, height: 25, marginRight: 5 }}
                disabled={searchText === ''}
              >
                {loading
                  ? (<CircularProgress size={20} thickness={4} />)
                  : (<Icon fontSize="small" sx={{ color: '#bdbdbd', fontSize: '20px !important' }}>close</Icon>
                  )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SearchBox);
