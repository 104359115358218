import React from 'react';
import styles from '../styles';

// Material UI
import { withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

function ChatWidgetIcon({ chatConfig, toggleChat, classes }) {
  let chatWidgetIcon = null;

  switch (chatConfig.widget_icon) {
    case 'floating-button':
      chatWidgetIcon = (
        <Fab
          color="primary"
          onClick={() => { toggleChat(); }}
          className={`${classes.fab} scc-main-fab`}
        >
          <Icon>chat</Icon>
        </Fab>
      );
      break;
    case 'box-with-text':
      chatWidgetIcon = (
        <Paper
          onClick={() => { toggleChat(); }}
          elevation={3}
          className={`${classes.fab} ${chatConfig.widget_position ? chatConfig.widget_position : 'bottom-right'} ${classes.boxWithText} scc-main-paper-box`}
          style={{ backgroundColor: chatConfig.primary_color }}
        >
          <Icon style={{ fontSize: 35 }}>chat</Icon>
          <Typography variant="h6">{chatConfig.title}</Typography>
        </Paper>
      );
      break;
    case 'custom-image':
      if (chatConfig.icon_image && chatConfig.icon_image !== '') {
        chatWidgetIcon = (
          <img
            src={chatConfig.icon_image} alt={chatConfig.title}
            onClick={() => { toggleChat(); }}
            className={`${chatConfig.widget_position ? chatConfig.widget_position : 'bottom-right'} ${classes.customImage} scc-main-paper-img`}
          />
        );
      }
      else {
        chatWidgetIcon = (
          <Fab
            color="primary"
            onClick={() => { toggleChat(); }}
            className={`${classes.fab} scc-main-fab`}
          >
            <Icon>chat</Icon>
          </Fab>
        );
      }
      break;
    default:
      chatWidgetIcon = (
        <Fab
          color="primary"
          onClick={() => { toggleChat(); }}
          className={`${classes.fab} scc-main-fab`}
        >
          <Icon>chat</Icon>
        </Fab>
      );
  }

  return chatWidgetIcon;
}

export default withStyles(styles)(ChatWidgetIcon);

