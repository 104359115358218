import React, { useEffect } from 'react';

// MUI
import { withStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';

// Other Components
import styles from '../styles';
import Footer from './Footer';

function Buttons({ config, toggleWidget, classes }) {
  useEffect(() => {
    if (config && config.omni_widget_channels && config.omni_widget_channels.length > 0 && config.omni_widget_channels.length === 1) {
      // toggleWidget();
    }
  }, [config, toggleWidget]);

  const openLink = (url, open) => {
    if (url && url !== '') {
      if (!open) {
        window.open(url, '_blank');
      } else {
        window.open(url, 'sagicc-chat-win', 'width=400,height=500,location=no,menubar=no,resizable=no,scrollbars=yes,status=no,titlebar=no,toolbar=no');
      }
    }
  };

  const getOmniWidgetButtons = () => {
    let items = [];
    if (config && config.omni_widget_channels && config.omni_widget_channels.length > 0) {
      items = config.omni_widget_channels.map((obj) => {
        let itemIcon = null;
        let itemClass = null;
        let openWindow = false;

        switch (obj.channel_type) {
          case 'whatsapp':
            itemIcon = 'fab fa-whatsapp';
            itemClass = classes.omniWidgetAvatarWhatsApp;
            break;
          case 'facebook':
            itemIcon = 'fab fa-facebook-messenger';
            itemClass = classes.omniWidgetAvatarFacebookMessenger;
            break;
          case 'telegram':
            itemIcon = 'fab fa-telegram';
            itemClass = classes.omniWidgetAvatarTelegram;
            break;
          case 'instagram':
            itemIcon = 'fab fa-instagram';
            itemClass = classes.omniWidgetAvatarInstagram;
            break;
          case 'chat':
            itemIcon = 'fas fa-comment';
            openWindow = true;
            break;
          case 'website':
            itemIcon = 'fas fa-clipboard-list';
            break;
          case 'email':
            itemIcon = 'fas fa-envelope';
            break;
          case 'twitter':
            itemIcon = 'fab fa-twitter';
            itemClass = classes.omniWidgetAvatarTwitter;
            break;
          default:
            itemIcon = obj.channel_icon || null;
            break;
        }

        return (
          <div className={`${classes.omniWidgetFabDiv}`} key={'button-' + obj.channel_id}>
            <Fab
              variant="extended"
              color="primary"
              onClick={() => { openLink(obj.url, openWindow); }}
              className={`${itemClass} ${classes.omniWidgetFabExtended}`}
              sx={obj.channel_color && obj.channel_color !== '' &&
              {
                borderColor: `${obj.channel_color} !important`,
                color: `${obj.channel_color} !important`,
                '&:hover': {
                  backgroundColor: `${obj.channel_color} !important`,
                  color: '#FFFFFF !important',
                }
              }}>
              <Icon className={`${itemIcon} ${classes.omniWidgetExtendedIcon}`} />
              {obj.channel_label && obj.channel_label !== ''
                ? obj.channel_label : (
                  obj.channel_name.length > 22 ? obj.channel_name.substring(0, 22) + "..." : obj.channel_name
                )}
            </Fab>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        {items}
        <div className={`${classes.omniWidgetCloseDiv}`}>
          <Footer />
          <Fab
            color="primary"
            onClick={toggleWidget}
            className={`${classes.fab} sowc-main-fab`}
            sx={{ alignSelf: 'flex-end', margin: '10px 0px 0px 0px !important' }}
          >
            <Icon>close</Icon>
          </Fab>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.sagiccOmniWidgetFloat}>
      {getOmniWidgetButtons()}
    </div>
  );
}

export default withStyles(styles)(Buttons);
