/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import { setChatConfig, handleNotificacion } from '../actions/chat';
import i18n from '../i18n';
import axiosInstance from '../constants/Api';
import Echo from 'laravel-echo';
import styles from './styles';

// Material UI
import { withStyles } from '@mui/styles';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';
import { darken } from '@mui/material/styles';

// Other Components
import WithExternalIframe from './WithExternalIframe';
import LoadingComponent from './LoadingComponent';
import ChatWidgetIcon from './Chat/ChatWidgetIcon';
import KnowledgeBase from './KnowledgeBase/KnowledgeBase';
const Chat = React.lazy(() => import('./Chat/Chat'));
const InitialForm = React.lazy(() => import('./Chat/InitialForm'));
const NotificationDialog = React.lazy(() => import('./Chat/NotificationDialog'));
const Header = React.lazy(() => import('./Chat/Header'));
const Footer = React.lazy(() => import('./Chat/Footer'));
const RatingDialog = React.lazy(() => import('./Chat/RatingDialog'));

function Main({ classes }) {
    const { chatToken, chatConfig, chatId, chatType, displayRatingDialog, containerId, browser } = useSelector(state => ({
        chatToken: state.chat.chatToken,
        chatConfig: state.chat.chatConfig,
        chatId: state.chat.chatId,
        chatType: state.chat.chatType,
        displayRatingDialog: state.chat.displayRatingDialog,
        containerId: state.chat.containerId,
        browser: state.browser
    }), shallowEqual);

    const [displayChat, setDisplayChat] = useState(chatType === 'fullscreen' ? true : false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customStyles, setCustomStyles] = useState(null);
    const [defaultView, setDefaultView] = useState('chat');
    const dispatch = useDispatch();

    useEffect(() => {
        if (chatId) { localStorage.setItem('SAGICC_CHAT_ID_NEW', 'false'); }
        getChatConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChatConfig = () => {
        setLoading(true);
        axiosInstance.get('/canal-chat/get-chat-conf', {
            params: {
                chat_token: chatToken,
                domain: window.location.hostname
            }
        }).then(function (response) {
            if (response.data.statusResult === true && response.data.status === 'OK') {
                dispatch(setChatConfig(response.data.result));

                const businesConfig = response.data.result.business_config ? response.data.result.business_config : null
                if (businesConfig && businesConfig.default_language) {
                    const selectedLanguage = businesConfig.default_language.toLowerCase();
                    i18n.changeLanguage(selectedLanguage);
                    localStorage.setItem('sagicc_chat_default_language', selectedLanguage);
                }

                // Adding CUSTOM STYLES to the CHAT WIDGET
                if (response.data.result.custom_style && response.data.result.custom_style !== '') {
                    const style = document.createElement('style');
                    style.type = 'text/css';
                    style.id = 'scc-custom-styles';

                    if (style.styleSheet) {
                        style.styleSheet.cssText = response.data.result.custom_style;
                    }
                    else {
                        style.appendChild(document.createTextNode(response.data.result.custom_style));
                    }

                    document.head.appendChild(style);

                    setCustomStyles(response.data.result.custom_style);
                }
            }
            else {
                dispatch(handleNotificacion(true, response.data.message));
            }

            if (chatConfig.unavailable_message && chatConfig.unavailable_message !== '') {
                if (chatConfig.chat_unavailable) {
                    dispatch(handleNotificacion(true, chatConfig.unavailable_message));
                    setButtonDisabled(chatConfig.hasOwnProperty('leave_message_when_not_available') ? !chatConfig.leave_message_when_not_available : false);
                }
                else {
                    if (!chatConfig.currently_available) {
                        dispatch(handleNotificacion(true, chatConfig.unavailable_message));
                        setButtonDisabled(chatConfig.hasOwnProperty('leave_message_when_not_available') ? !chatConfig.leave_message_when_not_available : false);
                    }
                }
            }

            let echoOptions = {
                broadcaster: 'socket.io',
                host: response.data.result.socket_io_port,
            };

            if (window.sagicc_chat_config && window.sagicc_chat_config.no_socket_header !== undefined && window.sagicc_chat_config.no_socket_header === true) {
                echoOptions.withoutInterceptors = true;
            }

            window.Echo = new Echo(echoOptions);

            setLoading(false);
        }).catch(function (error) {
            dispatch(handleNotificacion(true, i18n.t('error')));
            setLoading(false);
            console.log(error);
        });
    }

    const toggleChat = () => {
        setDisplayChat(!displayChat);
    }

    const theme = createTheme(adaptV4Theme({
        palette: {
            primary: {
                main: chatConfig.primary_color || '#1E1C66',
                contrastText: '#FFFFFF'
            },
            secondary: {
                main: chatConfig.secondary_color || '#D63344',
                contrastText: '#FFFFFF'
            },
            neutral: {
                main: '#E0E0E0',
                contrastText: '#000000'
            }
        }
    }));

    const duration = 200;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        display: 'none'
    }

    const transitionStyles = {
        entering: { opacity: 0 },
        entered: { opacity: 1, display: 'flex', flexDirection: 'column' }
    };

    if (!loading) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {customStyles && (
                        <style id="scc-custom-styles-ext" type="text/css">
                            {customStyles}
                        </style>
                    )}
                    <WithExternalIframe
                        title="with-material-ui"
                        chatType={chatType}
                        widgetPosition={chatConfig.widget_position}
                        widgetIcon={chatConfig.widget_icon}
                        displayChat={displayChat}
                        browser={browser}
                    >
                        {chatType === 'widget' && !displayChat ? (<ChatWidgetIcon chatConfig={chatConfig} toggleChat={() => { toggleChat(); }} />)
                            : (
                                <Transition in={displayChat} timeout={duration}>
                                    {(state) => (
                                        <Card
                                            id={containerId || 'sagicc-chat'}
                                            style={Object.assign({ width: '100%', height: '100%' }, defaultStyle, transitionStyles[state])}
                                            elevation={2}
                                        >
                                            <Suspense fallback={<LoadingComponent />}>
                                                <Header displayChat={displayChat} toggleChat={() => { toggleChat() }} />
                                                <CardContent style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-evenly',
                                                    padding: '0px 0px 10px 0px',
                                                    height: '24px',
                                                    backgroundColor: chatConfig.primary_color
                                                }}>
                                                    {chatConfig.enable_knowledgebase === true && (
                                                        <React.Fragment>
                                                            <Chip
                                                                icon={<Icon fontSize="small" sx={{ fontSize: '15px !important', marginRight: '5px' }}>library_books</Icon>}
                                                                label={i18n.t('knowledge_base.knowledge_base')}
                                                                variant="filled"
                                                                size="small"
                                                                color="primary"
                                                                clickable
                                                                style={{ backgroundColor: defaultView === 'knowledge_base' ? darken(chatConfig.primary_color || '#1E1C66', 0.2) : 'transparent', padding: '0px 5px' }}
                                                                onClick={() => { setDefaultView('knowledge_base'); }}
                                                            />
                                                            <Chip
                                                                icon={<Icon fontSize="small" sx={{ fontSize: '15px !important', marginRight: '5px' }}>chat</Icon>}
                                                                label={i18n.t('chat_online')}
                                                                variant="filled"
                                                                size="small"
                                                                color="primary"
                                                                clickable
                                                                style={{ backgroundColor: defaultView === 'chat' ? darken(chatConfig.primary_color || '#1E1C66', 0.2) : 'transparent', padding: '0px 5px' }}
                                                                onClick={() => { setDefaultView('chat'); }}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </CardContent>
                                                {defaultView === 'chat' ? (
                                                    <React.Fragment>
                                                        <NotificationDialog />
                                                        {displayRatingDialog && <RatingDialog />}
                                                        {chatId ? <Chat /> : <InitialForm buttonDisabled={buttonDisabled} />}
                                                    </React.Fragment>
                                                ) : (
                                                    <KnowledgeBase />
                                                )}
                                                <Footer />
                                            </Suspense>
                                        </Card>
                                    )}
                                </Transition>
                            )}
                    </WithExternalIframe>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    else return null;
}

export default withStyles(styles)(Main);
