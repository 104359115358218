import { all, fork, put, takeEvery, select, call } from 'redux-saga/effects';
import {
  END_CHAT_EXIT
} from '../constants/ActionTypes';
//
import { exitChatSuccess, handleChatEndLoanding, handleNotificacion, handleRatingDialog } from '../actions/chat';
import axiosInstance from '../constants/Api';
import i18n from '../i18n';

/**
 * 
*/
function* fnEndChat() {
  try {
    yield put(handleChatEndLoanding(true));

    const chatData = state => state.chat;
    const { chatToken, chatId, transcription, initialForm } = yield select(chatData);

    yield call(() => axiosInstance.post('/canal-chat/endChat', {
      chat_token: chatToken,
      domain: window.location.hostname,
      chat_id: chatId,
      transcription: transcription,
      email: initialForm.email
    })
      .then((response) => response.data)
      .catch((error) => { throw error; }));

    localStorage.removeItem('SAGICC_CHAT_ID');
    localStorage.removeItem('SAGICC_CLIENT_NRO_DOCUMENTO');
    localStorage.removeItem('SAGICC_CLIENT_FIRST_NAME');
    localStorage.removeItem('SAGICC_CLIENT_LAST_NAME');
    localStorage.removeItem('SAGICC_CLIENT_EMAIL');
    localStorage.removeItem('SAGICC_CLIENT_PHONE');
    localStorage.removeItem('SAGICC_CHAT_ID_NEW');
    localStorage.removeItem('SAGICC_CHAT_SOUND');
    localStorage.removeItem('SAGICC_CHAT_TRANSCRIPTION');

    yield put(exitChatSuccess());
    yield put(handleChatEndLoanding(false));
    yield put(handleRatingDialog(false, null));

  } catch (error) {
    yield put(handleChatEndLoanding(false));
    yield put(handleNotificacion(true, i18n.t('error')));
    console.log('ERROR - END CHAT: ', error)
  }
}

export function* endChatExit() {
  yield takeEvery(END_CHAT_EXIT, fnEndChat);
}

export default function* rootSaga() {
  yield all([
    fork(endChatExit)
  ]);
}
