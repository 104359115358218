import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Transition from 'react-transition-group/Transition';

// MUI
import { withStyles } from '@mui/styles';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';

// Other Components
import axiosInstance from '../../constants/Api';
import styles from '../styles';
import WithExternalIframe from '../WithExternalIframe';
import OmniIcon from './OmniIcon';
import Inner from './Inner';

function OmniWidget({ classes }) {
    const { widgetToken } = useSelector(state => ({
        widgetToken: state.chat.widgetToken,
    }), shallowEqual);

    const [displayWidget, setDisplayWidget] = useState(false);
    const [omniWidgetConfig, setOmniWidgetConfig] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getChatConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getChatConfig = () => {
        setLoading(true);
        axiosInstance.get('integracion-sagicc-externo/omni-widget/getWidgetConfig', {
            params: {
                widget_token: widgetToken,
                domain: window.location.hostname
            }
        }).then(function (response) {
            if (response.data.statusResult === true && response.data.status === 'OK') {
                setOmniWidgetConfig(response.data.result);
            }
            else {
                console.log(response.data.message);
            }

            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            console.log(error);
        });
    }

    const toggleWidget = useCallback(() => {
        setDisplayWidget(!displayWidget);
    }, [displayWidget]);

    const theme = createTheme(adaptV4Theme({
        palette: {
            primary: {
                main: omniWidgetConfig.omni_widget_primary_color || '#1E1C66'
            },
            secondary: {
                main: omniWidgetConfig.omni_widget_primary_color || '#D63344'
            },
            neutral: {
                main: '#E0E0E0',
                contrastText: '#000000'
            }
        }
    }));

    if (!loading) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <WithExternalIframe
                        chatType={omniWidgetConfig.omni_widget_style}
                        widgetPosition={omniWidgetConfig.omni_widget_position}
                        widgetIcon={omniWidgetConfig.omni_widget_icon}
                        displayChat={displayWidget}
                        height={omniWidgetConfig.omni_widget_style === 'omni-widget-floating' && omniWidgetConfig.omni_widget_channels && (omniWidgetConfig.omni_widget_channels.length * 75) + 86}
                        title="sagicc-frame-onmiwidget"
                    >
                        <Transition in={displayWidget} timeout={200}>
                            {(state) => (
                                <Inner
                                    omniConfig={omniWidgetConfig}
                                    displayWidget={displayWidget}
                                    toggleWidget={toggleWidget}
                                    state={state}
                                />
                            )}
                        </Transition>
                        <OmniIcon omniConfig={omniWidgetConfig} displayWidget={displayWidget} toggleWidget={toggleWidget} />
                    </WithExternalIframe>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    else return null;
}

export default withStyles(styles)(OmniWidget);
