/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { Provider } from 'react-redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import thunk from 'redux-thunk';
import reducers from './reducers';
import rootSaga from './sagas';

// AJUSTANDO PUBLIC PATH PARA BUSCAR ASSETS DEL CHAT
if (window.location.hostname !== 'localhost' && window.location.hostname !== '') {
  if (localStorage.getItem('SAGICC_CHAT_STAGING') && localStorage.getItem('SAGICC_CHAT_STAGING') === 'true') {
    __webpack_public_path__ = 'https://static.sagicc.co/chat/staging/';
    localStorage.setItem('SAGICC_CHAT_PUBLIC_PATH', 'https://static.sagicc.co/chat/staging/');
  } else {
    __webpack_public_path__ = 'https://static.sagicc.co/chat/v2/';
    localStorage.setItem('SAGICC_CHAT_PUBLIC_PATH', 'https://static.sagicc.co/chat/v2/');
  }
}

//Adding STYLES and SCRIPTS dinamically
const rootDiv = document.createElement('div');
rootDiv.setAttribute('id', 'sagicc-chat-div');
document.body.appendChild(rootDiv);

const chatType = window.sagicc_chat_config ? window.sagicc_chat_config.chat_type : null;

if (chatType === 'fullscreen') {
  const htmlDiv = document.getElementsByTagName('html')[0];
  htmlDiv.style.margin = '0px';
  const bodyDiv = document.getElementsByTagName('body')[0];
  bodyDiv.style.margin = '0px';
}

function addScript(src, callback) {
  const s = document.createElement('script');
  s.setAttribute('src', src);
  s.onload = callback;
  document.body.appendChild(s);
}

function addLink(href) {
  const h = document.createElement('link');
  h.setAttribute('href', href);
  h.setAttribute('rel', 'stylesheet');
  document.head.appendChild(h);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  composeEnhancers(
    responsiveStoreEnhancer,
    applyMiddleware(sagaMiddleware, thunk)
  )
);

sagaMiddleware.run(rootSaga);

addLink('https://fonts.googleapis.com/css?family=Nunito:300,400,500');
addLink('https://fonts.googleapis.com/icon?family=Material+Icons');

addScript('https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.0.4/socket.io.js', () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('sagicc-chat-div')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
