import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Main from './components/Main';
import OmniWidget from './components/Omniwidget/OmniWidget';

function App() {
  const { widgetToken } = useSelector(state => ({
    widgetToken: state.chat.widgetToken
  }), shallowEqual);

  if (widgetToken && widgetToken !== '') {
    return <OmniWidget />
  }

  return (
    <Main />
  );
}

export default App;
