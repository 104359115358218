import React, { useState, useEffect } from 'react';
import styles from '../styles';

// Material UI
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';

import axiosInstance from '../../constants/Api';
import moment from '../../constants/Moment';
import i18n from '../../i18n';

function ArticleContent({ articleHash, hideContent, classes }) {
  const [selectedArticle, setSelectedArticle] = useState(false);
  const [loading, setLoading] = useState(false);

  const getArticleDetail = () => {
    setLoading(true);
    axiosInstance.get('/integracion-sagicc-externo/knowledge-base/getArticleInfo/' + articleHash).then(function (response) {
      if (response.data.statusResult === true && response.data.status === 'OK') {
        setSelectedArticle(response.data.result);
      }
      setLoading(false);
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    });
  };

  useEffect(() => {
    getArticleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleHash]);

  const downloadAttachment = (attachmentId) => {
    axiosInstance.post('/integracion-sagicc-externo/knowledge-base/downloadAttachment/' + attachmentId)
      .then((response) => {
        if (response) {
          window.open(response);
        } else {
          console.log('Error');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAttachments = () => {
    if (selectedArticle && selectedArticle.adjuntos && selectedArticle.adjuntos.length > 0) {
      return selectedArticle.adjuntos.map((obj) => (
        <Chip
          icon={<Icon>attachment</Icon>}
          label={obj.real_name && obj.real_name !== '' ? obj.real_name : obj.file_path}
          color="primary"
          onClick={() => { downloadAttachment(obj.id); }}
          className={classes.chipAttachment}
        />
      ));
    }
  };

  if (loading) return '';

  return (
    <Grow in={articleHash}>
      <Paper elevation={3} className={classes.kbArticleContentPaper}>
        <div className={classes.kbArticleContentTop}>
          <IconButton
            color="inherit"
            onClick={(event) => { window.open(`${window.sagicc_chat_config.back_url}/api/v2/integracion-sagicc-externo/knowledge-base/getView/article/${selectedArticle.hashed_id}`, 'popup', 'width=600,height=600,scrollbars=no,resizable=no'); }}
            size="small">
            <Icon fontSize="small" sx={{ fontSize: '18px !important', color: '#616161' }}>open_in_new</Icon>
          </IconButton>
          <IconButton
            color="inherit"
            onClick={(event) => { hideContent(); }}
            size="small">
            <Icon fontSize="small" sx={{ fontSize: '18px !important', color: '#616161' }}>close</Icon>
          </IconButton>
        </ div>
        <Typography gutterBottom variant="h6" component="h2" sx={{ lineHeight: '1.4' }}>
          {selectedArticle.titulo}
        </Typography>
        <div className={classes.kbArticleContentlabels}>
          {selectedArticle.etiquetas && selectedArticle.etiquetas.map((obj) => (
            <Chip label={obj} size="small" color="primary" />
          ))}
        </div>
        <Typography gutterBottom variant="caption" component="p">
          {i18n.t('knowledge_base.last_updated')}
          <br />
          {moment(selectedArticle.updated_at).format('ddd, DD MMMM YYYY, hh:mm A')}
        </Typography>
        <Divider className={classes.kbArticleContentDivider} />
        <Typography component="p">
          <span dangerouslySetInnerHTML={{
            __html: (`
                    <style type="text/css">
                      .resetHtml > * {
                        position: inherit !important;
                        height: initial !important;
                        min-height: initial !important;
                        width: initial !important;
                        min-width: initial !important;
                      }
                    </style>
                    <div class= "resetHtml">
                       ${selectedArticle.cuerpo}
                    </div>
                  `)
          }}
          />
        </Typography>
        {getAttachments()}
      </Paper>
    </ Grow >
  );
}

export default withStyles(styles)(ArticleContent);
