export const SET_INITIAL_FORM_DATA = 'SET_INITIAL_FORM_DATA';
export const SET_CHAT_CONFIG = 'SET_CHAT_CONFIG';
export const SET_CHAT_ID = 'SET_CHAT_ID';
export const SET_AGENTS_AVAILABLE = 'SET_AGENTS_AVAILABLE';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const HANDLE_END_CHAT_DIALOG = 'HANDLE_END_CHAT_DIALOG';
export const END_CHAT_EXIT = 'END_CHAT_EXIT';
export const EXIT_CHAT_SUCCESS = 'EXIT_CHAT_SUCCESS';
export const HANDLE_CHAT_END_LOADING = 'HANDLE_CHAT_END_LOADING';
export const HANDLE_SOUND = 'HANDLE_SOUND';
export const HANDLE_TRANSCRIPTION = 'HANDLE_TRANSCRIPTION';
export const HANDLE_NOTIFICATION = 'HANDLE_NOTIFICATION';
export const HANDLE_RATING_DIALOG = 'HANDLE_RATING_DIALOG';
export const SET_CHAT_TYPE = 'SET_CHAT_TYPE';
export const SET_ORIGINAL_DIMENSION = 'SET_ORIGINAL_DIMENSION';

export const VIDEO_CHAT_TOGGLE_VIEW = 'VIDEO_CHAT_TOGGLE_VIEW';

export const VIDEO_CHAT_INITIALIZING_ROOM = 'VIDEO_CHAT_INITIALIZING_ROOM';
export const VIDEO_CHAT_ENTER_ROOM = 'VIDEO_CHAT_ENTER_ROOM';
export const VIDEO_CHAT_EXIT_ROOM = 'VIDEO_CHAT_EXIT_ROOM';

export const VIDEO_CHAT_TOGGLE_MIC = 'VIDEO_CHAT_TOGGLE_MIC';
export const VIDEO_CHAT_TOGGLE_SPEAKER = 'VIDEO_CHAT_TOGGLE_SPEAKER';
export const VIDEO_CHAT_TOGGLE_VIDEO = 'VIDEO_CHAT_TOGGLE_VIDEO';
export const VIDEO_CHAT_TOGGLE_CHAT = 'VIDEO_CHAT_TOGGLE_CHAT';
export const VIDEO_CHAT_TOGGLE_SETTINGS = 'VIDEO_CHAT_TOGGLE_SETTINGS';
export const VIDEO_CHAT_SAVE_SETTINGS = 'VIDEO_CHAT_SAVE_SETTINGS';

export const VIDEO_CHAT_TOGGLE_WINDOW = 'VIDEO_CHAT_TOGGLE_WINDOW';

export const VIDEO_CHAT_SET_ROOM = 'VIDEO_CHAT_SET_ROOM';
export const VIDEO_CHAT_SET_CONVERSATION = 'VIDEO_CHAT_SET_CONVERSATION';
export const VIDEO_CHAT_SET_OV = 'VIDEO_CHAT_SET_OV';

export const VIDEO_CHAT_SET_SESSION = 'VIDEO_CHAT_SET_SESSION';
export const VIDEO_CHAT_SET_LOCAL_USER = 'VIDEO_CHAT_SET_LOCAL_USER';
export const VIDEO_CHAT_SET_REMOTE_USER = 'VIDEO_CHAT_SET_REMOTE_USER';

export const FORCE_STATE = 'FORCE_STATE';
