import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styles from './styles';

// Material UI
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

function Footer({ componentHeight, classes }) {
    const { chatConfig, chatType } = useSelector(state => ({
        chatConfig: state.chat.chatConfig,
        chatType: state.chat.chatType
    }), shallowEqual);

    return (
        <Paper className={`${classes.loadingDialog} ${chatType === 'fullscreen' && classes.dialogFullscreen} scc-loading-component`}>
            <CircularProgress
                size={70}
                thickness={5}
                color={chatConfig.secondary_color}
                className={classes.loadingComponent}
            />
        </Paper>
    );
}

export default withStyles(styles)(Footer);