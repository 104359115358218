/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { create } from 'jss';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import {
  StylesProvider,
  jssPreset,
  withStyles
} from '@mui/styles';
import styles from './styles';

const WithExternalIframe = ({
  children,
  styleSelector,
  title,
  chatType,
  widgetPosition,
  widgetIcon,
  displayChat,
  height,
  browser,
  classes,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null);
  const [mountNode, setMountNode] = useState(contentRef?.contentWindow?.document?.body);
  const [mounted, setMounted] = useState(false);

  const insertionPoint = contentRef?.contentWindow?.document?.head;
  const jss = useMemo(
    () =>
      insertionPoint &&
      create({
        ...jssPreset(),
        insertionPoint
      }),
    [insertionPoint]
  );

  const cache = createCache({
    key: 'css',
    container: contentRef?.contentWindow?.document?.head,
    prepend: true
  })

  const mountContent = () => {
    if (!contentRef) { return; }
    setMountNode(contentRef?.contentWindow?.document?.body);

    const win = contentRef?.contentWindow;
    const customStylesLink = win.parent.document.getElementById('scc-custom-styles');
    if (customStylesLink) { win.document.head.appendChild(customStylesLink); }

    const emojiLink = document.createElement('link');
    emojiLink.href = localStorage.getItem('SAGICC_CHAT_PUBLIC_PATH') + 'emoji-picker-react.css';
    emojiLink.rel = 'stylesheet';
    win.document.head.appendChild(emojiLink);

    const fontLink = document.createElement('link');
    fontLink.href = 'https://fonts.googleapis.com/css?family=Nunito:300,400,500';
    fontLink.rel = 'stylesheet';
    win.document.head.appendChild(fontLink);

    const muiIconsLink = document.createElement('link');
    muiIconsLink.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
    muiIconsLink.rel = 'stylesheet';
    win.document.head.appendChild(muiIconsLink);

    const faIconsLink = document.createElement('link');
    faIconsLink.href = 'https://use.fontawesome.com/releases/v5.12.0/css/all.css';
    faIconsLink.rel = 'stylesheet';
    win.document.head.appendChild(faIconsLink);

    const bodyStyle = document.createElement('style');
    bodyStyle.type = 'text/css';
    bodyStyle.id = 'scc-body-styles';
    bodyStyle.appendChild(document.createTextNode('body { padding: 0px !important; position: relative; }'));
    win.document.head.appendChild(bodyStyle);

    win.document.body.classList.add("scc-body");

    setMounted(true);
  };

  useEffect(() => {
    if (!navigator.userAgent.match(/firefox|fxios/i)) {
      mountContent();
    }
  }, [contentRef]);

  return (
    <iframe
      title={title}
      ref={setContentRef}
      id="scc-iframe"
      importante="low"
      scrolling="no"
      onLoad={() => { if (!mounted) mountContent(); }}
      height={height || '100%'}
      style={{ height: chatType === 'fullscreen' && (browser.height - 5) }}
      className={`${classes.sagiccChatIFrame}
      scc-${chatType === 'fullscreen' ? chatType : (widgetPosition ? widgetPosition : 'bottom-right')}
      scc-${(displayChat ? chatType : widgetIcon)}
      `}
      {...props}
    >
      {mountNode &&
        createPortal(
          <CacheProvider value={cache}>
            <StylesProvider jss={jss}>
              {children}
            </StylesProvider>
          </CacheProvider>,
          mountNode
        )}
    </iframe>
  )
}

export default withStyles(styles)(WithExternalIframe);