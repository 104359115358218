import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import styles from '../styles';

// Material UI
import { withStyles } from '@mui/styles';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

import ArticleContent from './ArticleContent';

function ArticleCard({ article, classes }) {
  const { chatConfig } = useSelector(state => ({
    chatConfig: state.chat.chatConfig
  }), shallowEqual);
  const [displayContent, setDisplayContent] = useState(false);

  return (
    <React.Fragment>
      <ListItemButton
        onClick={() => { setDisplayContent(true); }}
        alignItems="flex-start" sx={{ paddingLeft: '5px', paddingRight: '5px' }}
      >
        <ListItemAvatar sx={{ minWidth: 50 }}>
          <Avatar sx={{ bgcolor: chatConfig.primary_color || '#1E1C66', height: 38, width: 38 }}>
            <Icon fontSize="small" sx={{ fontSize: '20px !important' }}>library_books</Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="body2" display="block">
              {article.titulo}
            </Typography>
          }
          secondary={
            <React.Fragment>
              <Typography variant="caption" display="block">
                {`${article.seccion_nombre} / ${article.categoria_nombre}`}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider component="li" />
      {displayContent && <ArticleContent articleHash={article.hashed_id} hideContent={() => { setDisplayContent(false); }} />}
    </React.Fragment >
  );
}

export default withStyles(styles)(ArticleCard);
