import React from 'react';

// MUI
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

// Other Components
import sagiccLogo from '../../assets/img/sagicc-grey.png';
import styles from '../styles';

function Footer({ classes }) {
  return (
    <Typography variant="caption" component="div" id="sowc-footer-caption" className={classes.omniWidgetFooterDiv}>
      Powered by
      <Link
        href="https://www.sagicc.co"
        target="_blank"
        rel='noopener noreferrer'
        variant="caption">
        <img src={sagiccLogo} alt="Sagicc" className={classes.footerImg} />
      </Link>
    </Typography>
  );
}

export default withStyles(styles)(Footer);
