const styles = (theme) => ({
  // IFRAME
  sagiccChatIFrame: {
    border: 'none',
    margin: 10,
    position: 'fixed',
    '&.scc-top-left': {
      top: '0px',
      left: '0'
    },
    '&.scc-top-right': {
      top: '0px',
      right: '0'
    },
    '&.scc-bottom-left': {
      bottom: '0px',
      left: '0'
    },
    '&.scc-bottom-right': {
      bottom: '0px',
      right: '0'
    },
    '&.scc-widget': {
      width: '360px',
      height: '530px',
      zIndex: '999999',
      position: 'fixed'
    },
    '&.scc-floating-button': {
      width: '70px !important',
      height: '70px !important',
      margin: '10px !important',
      zIndex: '999999 !important',
      position: 'fixed !important'
    },
    '&.scc-box-with-text': {
      minHeight: '60px !important',
      maxHeight: '100px !important',
      width: '300px !important',
      margin: '10px !important',
      zIndex: '999999 !important',
      position: 'fixed !important'
    },
    '&.scc-custom-image': {
      maxHeight: '150px !important',
      // margin: '10px !important',
      zIndex: '999999 !important',
      position: 'fixed !important'
    },
    '&.scc-fullscreen': {
      width: '100% !important',
      backgroundColor: '#FFFFFF !important',
      margin: 0,
      padding: 0
    },
    '&.scc-omni-widget-list': {
      width: '360px',
      height: '530px',
      zIndex: '999999',
      position: 'fixed'
    },
    '&.scc-omni-widget-floating': {
      zIndex: '999999',
      position: 'fixed'
    }
  },
  // END IFRAME

  // CHAT WIDGET ICON
  fab: {
    margin: '-5px -5px',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 30%)'
  },
  boxWithText: {
    padding: '10px 15px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#FFFFFF',
    '& span': {
      margin: 10
    },
    '& h6': {
      lineHeight: '1.4',
      textAlign: 'center'
    }
  },
  customImage: {
    cursor: 'pointer',
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  // END CHAT WIDGET ICON

  // APP BAR
  appBarTitle: {
    flexGrow: 1
  },
  appBarAvatar: {
    marginRight: 10
  },
  toolbar: {
    minHeight: 60,
    height: 60,
    paddingLeft: 20,
    paddingRight: 10
  },
  // END APP BAR

  initialForm: {
    padding: '0px',
    height: 'calc(100% - 80px)'
  },
  initialFormTop: {
    height: 'calc(100% - 80px)',
    '& >div>div': {
      overflowX: 'hidden !important',
      marginBottom: '0px !important'
    }
  },
  initialFormTopInner: {
    margin: '15px 15px 0px 15px'
  },
  initialFormBottom: {
    padding: '10px'
  },
  initialFormTextField: {
    marginBottom: 15
  },
  textField: {
    '& textarea': {
      fontSize: 16,
      lineHeight: '20px',
      padding: '0',
      height: 'auto',
      boxShadow: 'none',
      minHeight: 'auto'
    },
    '& input': {
      fontSize: 16,
      lineHeight: '20px',
      padding: '18.5px 14px',
      height: 'auto',
      boxShadow: 'none',
      minHeight: 'auto'
    },
    '& label': {
      fontSize: 16,
      textTransform: 'none',
      letterSpacing: 'normal',
      marginBottom: '0px'
    },
    '& .MuiOutlinedInput-root': {
      fontSize: 16
    }
  },
  termsConditions: {
    marginBottom: 5,
    textAlign: 'center'
  },
  termsConditionsLinkHigh: {
    fontSize: 11,
    color: '#1E1C66'
  },
  termsConditionsLink: {
    fontSize: 11,
    textDecoration: 'none',
    color: '#616161'
  },
  footer: {
    borderTop: '#D4D4D4 solid 1px',
    height: 20,
    display: 'flex',
    padding: '0px 10px',
    '& .MuiTypography-caption': {
      display: 'flex',
      alignItems: 'center'
    },
    '& img': {
      marginLeft: 5
    },
    '& span': {
      fontSize: 10
    }
  },
  footerFullscreen: {
    justifyContent: 'center'
  },
  footerWidget: {
    justifyContent: 'flex-end'
  },
  footerImg: {
    height: '11px'
  },
  messageList: {
    padding: '5px 15px 0px 10px',
    paddingBottom: '0px !important',
    backgroundColor: '#FFFFFF'
  },
  messageBubbleTopIn: {
    paddingRight: 50,
    letterSpacing: '0.01',
    fontSize: '70%'
  },
  messageBubbleTopOut: {
    paddingLeft: 50,
    letterSpacing: '0.01',
    fontSize: '70%'
  },
  messageBubble: {
    display: 'flex'
  },
  messageBubbleIn: {
    display: 'block',
    backgroundColor: '#ECEFF1',
    borderRadius: '15px 0px 15px 15px',
    margin: '3px 10px 15px 0px !important',
    cursor: 'default',
    boxShadow: '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
    '&:before': {
      content: '',
      position: 'absolute',
      right: '-12px',
      top: '0px',
      border: '6px solid',
      borderColor: '#EEEEEE transparent transparent #EEEEEE'
    }
  },
  messageBubbleOut: {
    display: 'block',
    backgroundColor: '#ECEFF1',
    borderRadius: '0px 15px 15px 15px',
    margin: '3px 0px 15px 10px',
    cursor: 'default',
    boxShadow: '0px 1px 3px 0px rgba(142, 142, 142, 0.2), 0px 1px 1px 0px rgba(243, 243, 243, 0.14), 0px 2px 1px -1px rgba(204, 204, 204, 0.12)',
    '&:before': {
      content: '',
      position: 'absolute',
      left: '-12px',
      top: '0px',
      border: '6px solid',
      borderColor: '#EEEEEE #EEEEEE transparent transparent'
    }
  },
  messagePre: {
    margin: 0,
    padding: 0,
    whiteSpace: 'pre-wrap',
    fontFamily: 'inherit',
    fontSize: '14px',
    overflowX: 'hidden',
    lineHeight: '1.4',
    overflowWrap: 'anywhere',
    backgroundColor: 'inherit !important',
    border: 'none !important',
    overflowY: 'clip !important'
  },
  chatMessageBox: {
    padding: 8,
    paddingBottom: '8px !important',
    backgroundColor: '#E8E8E8'
  },
  chatMessageBoxForm: {
    display: 'flex',
    margin: 0,
    alignItems: 'center',
    position: 'relative'
  },
  chatMessageBoxEmojiButton: {
    position: 'absolute',
    left: '8px',
    bottom: '8px',
    zIndex: '999999',
    width: '40px',
    height: '40px',
    padding: '0px'
  },
  chatMessageBoxInput: {
    '& .MuiInputBase-root': {
      backgroundColor: '#FFFFFF',
      paddingLeft: 54,
      paddingRight: 54,
      borderRadius: 24
    }
  },
  chatMessageBoxSendButton: {
    position: 'absolute',
    right: '8px',
    bottom: '8px',
    zIndex: '999999',
    width: '40px',
    height: '40px',
    padding: '0px',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 30%)'
  },
  notificationDialog: {
    position: 'fixed',
    zIndex: '999999',
    width: '264px',
    margin: '20px',
    padding: '20px',
    textAlign: 'center'
  },
  dialogFullscreen: {
    width: 'calc(100% - 95px)'
  },
  endChatButton: {
    marginBottom: 10
  },
  loadingComponentDiv: {
    textAlign: 'center',
    position: 'relative'
  },
  loadingComponent: {
    margin: '20px 0px'
  },
  starsRating: {
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    margin: '20px 0px'
  },
  starsRatingError: {
    outline: '#F44336 solid 2px'
  },
  button: {
    '& .MuiButton-label': {
      fontFamily: 'Nunito, Helvetica, Arial, sans-serif !important'
    }
  },
  loadingDialog: {
    position: 'fixed',
    zIndex: '999999',
    minWidth: '264px',
    margin: '20px',
    padding: '20px',
    textAlign: 'center'
  },

  // CHATWEB INTERACTIVE
  cwInteractiveContentHeaderUrl: {
    margin: '10px 0px'
  },
  cwInteractiveContentHeaderText: {
    margin: '8px 0px',
    color: 'rgb(67, 83, 105)',
    fontSize: '17px',
    fontWeight: '600',
    fontFamily: 'Nunito, Helvetica, Arial, sans-serif !important'
  },
  cwInteractiveContentFooter: {
    margin: '8px 0px',
    color: 'rgb(104, 121, 146)',
    fontSize: '12px',
    fontFamily: 'Nunito, Helvetica, Arial, sans-serif !important'
  },
  cwInteractiveButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginTop: '10px'
  },
  cwInteractiveButton: {
    textAlign: 'center',
    margin: '0 5px 10px 5px',
    background: 'rgb(255, 255, 255)',
    boxShadow: 'rgb(195 205 221) 0px 1px 2px',
    borderRadius: '20px',
    color: 'rgb(67, 83, 105)',
    fontWeight: '600',
    fontSize: '14px',
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Nunito, Helvetica, Arial, sans-serif !important',
    '&:hover': {
      //backgroundColor: 'rgba(25, 118, 210, 0.04)',
      cursor: 'pointer',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    }
  },

  // OMNI WIDGET
  omniWidgetAppBar: {
    borderRadius: '12px 12px 0 0'
  },
  sagiccOmniWidgetDiv: {
    display: 'flex',
    flexDirection: 'column',
    '&.sowc-top-left': {
      zIndex: '999999',
      top: '0px',
      left: '0px'
    },
    '&.sowc-top-right': {
      zIndex: '999999',
      top: '0px',
      right: '0px'
    },
    '&.sowc-bottom-left': {
      zIndex: '999999',
      bottom: '0px',
      left: '0px'
    },
    '&.sowc-bottom-right': {
      zIndex: '999999',
      bottom: '0',
      right: '0'
    },
    '&.sowc-omni-widget-list': {
      width: '100%',
      height: '100%'
    },
    '&.sowc-omni-widget-floating': {
      width: 'auto',
      position: 'absolute',
      padding: '0px 8px !important'
    }
  },
  sagiccOmniWidgetInner: {
    height: '415px',
    padding: '20px 28px 20px 28px',
    overflowY: 'scroll',
    borderRadius: '0px 0px 12px 12px',
  },
  sagiccOmniWidgetFloat: {
    // margin: '10px 0px'
  },
  omniWidgetListItem: {
    /*boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
    borderRadius: '4px',*/
    marginBottom: 10,
    textTransform: 'none',
    padding: '10px 20px',
    borderRadius: '30px',
    border: theme && theme.palette ? (theme.palette.primary.main + ' 2px solid') : '#1E1C66 2px solid',
    color: theme && theme.palette ? theme.palette.primary.main : '#1E1C66',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: theme && theme.palette ? theme.palette.primary.main : '#1E1C66',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetFabExtended: {
    textTransform: 'none',
    padding: '10px 14px',
    borderRadius: '30px',
    height: 'auto',
    border: theme && theme.palette ? (theme.palette.primary.main + ' 2px solid') : '#1E1C66 2px solid',
    color: theme && theme.palette ? theme.palette.primary.main : '#1E1C66',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: theme && theme.palette ? theme.palette.primary.main : '#1E1C66',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetFabDiv: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  omniWidgetCloseDiv: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  omniWidgetAvatarWhatsApp: {
    border: '#25d366 2px solid',
    color: '#25d366',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#25d366',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetAvatarFacebookMessenger: {
    border: '#0078ff 2px solid',
    color: '#0078ff',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#0078ff',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetAvatarTelegram: {
    border: '#0088cc 2px solid',
    color: '#0088cc',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#0088cc',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetAvatarInstagram: {
    border: '#c32aa3 2px solid',
    color: '#c32aa3',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#c32aa3',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetAvatarTwitter: {
    border: '#1da1f2 2px solid',
    color: '#1da1f2',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#1da1f2',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetAvatarFacebook: {
    border: '#1877f2 2px solid',
    color: '#1877f2',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 2px 0px rgb(0 0 0 / 20%)',
    '&:hover': {
      backgroundColor: '#1877f2',
      color: '#FFFFFF',
      transform: 'scale(1.1)'
    }
  },
  omniWidgetExtendedIcon: {
    marginRight: 10,
    fontSize: '25px',
    width: '32px',
    /* '& .omniWidgetAvatarTwitter': {
      border: '#1da1f2 2px solid'
    } */
  },
  omniWidgetIcon: {
    marginRight: '-1px'
  },
  omniWidgetFooterDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    lineHeight: '0.5',
    color: '#898989',
    fontSize: 10,
    marginTop: 10,
    '& a': {
      lineHeight: '0.5',
      marginLeft: 3
    }
  },
  // END OMNIWIDGET

  // KNOWLEDGEBASE
  kbCardContent: {
    height: '100%',
    padding: 0,
    margin: 0
  },
  kbCardContentInnerDiv: {
    height: 'calc(100% - 90px)'
  },
  kbSearchBox: {
    padding: '15px 15px 10px 15px'
  },
  kbCardContentTitle: {
    padding: '0px 15px',
    color: '#616161'
  },
  kbArticleContentPaper: {
    padding: '40px 15px 15px 15px',
    margin: '10px 5px 10px 0',
    position: 'relative'
  },
  kbArticleContentTop: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  kbArticleContentlabels: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '5px 5px 5px 0px',
    '& > *': {
      margin: '5px 5px 5px 0px',
      height: '18px',
      fontSize: '70%'
    }
  },
  kbArticleContentDivider: {
    margin: '5px 0 10px 0'
  }
  // END KNOWLEDGE BASE
});

export default styles;
