import { combineReducers } from 'redux';
import chat from './chat';
import { createResponsiveStateReducer } from 'redux-responsive'
import videochat from './videochat';

const appReducer = combineReducers({
    chat: chat,
    videoChat: videochat,
    browser: createResponsiveStateReducer(null, {
        extraFields: () => ({
            width: window.innerWidth,
            height: window.innerHeight
        }),
    })
});

const rootReducer = (state, action) => {
    if (action.type === 'END_CHAT') {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer;