import {
    SET_CHAT_CONFIG,
    SET_INITIAL_FORM_DATA,
    SET_CHAT_ID,
    SET_AGENTS_AVAILABLE,
    ADD_MESSAGES,
    HANDLE_END_CHAT_DIALOG,
    END_CHAT_EXIT,
    EXIT_CHAT_SUCCESS,
    HANDLE_CHAT_END_LOADING,
    HANDLE_SOUND,
    HANDLE_TRANSCRIPTION,
    HANDLE_NOTIFICATION,
    HANDLE_RATING_DIALOG,
    SET_ORIGINAL_DIMENSION,
    SET_CHAT_TYPE
} from '../constants/ActionTypes';

export function setChatConfig(data) {
    return {
        type: SET_CHAT_CONFIG,
        data: data
    };
}

export function setInitialFormData(data) {
    return {
        type: SET_INITIAL_FORM_DATA,
        data: data
    };
}

export function setChatId(chatId) {
    return {
        type: SET_CHAT_ID,
        chatId: chatId
    };
}

export function setAgentsAvailable(agentsAvailable) {
    return {
        type: SET_AGENTS_AVAILABLE,
        agentsAvailable: agentsAvailable
    };
}

export function addMessages(messages) {
    return {
        type: ADD_MESSAGES,
        messages: messages
    };
}

export function handleEndChatDialog(displayEndChatDialog) {
    return {
        type: HANDLE_END_CHAT_DIALOG,
        displayEndChatDialog
    };
}

export function endChatExit() {
    return {
        type: END_CHAT_EXIT
    };
}

export function exitChatSuccess() {
    return {
        type: EXIT_CHAT_SUCCESS
    };
}

export function handleChatEndLoanding(displayChatEndLoading) {
    return {
        type: HANDLE_CHAT_END_LOADING,
        displayChatEndLoading
    }
}

export function handleSound() {
    return {
        type: HANDLE_SOUND
    }
}

export function handleTranscription() {
    return {
        type: HANDLE_TRANSCRIPTION
    }
}

export function handleNotificacion(displayNotification, notificationMessage = '') {
    return {
        type: HANDLE_NOTIFICATION,
        displayNotification: displayNotification,
        notificationMessage: notificationMessage
    }
}

export function handleRatingDialog(displayRatingDialog, ratingId) {
    return {
        type: HANDLE_RATING_DIALOG,
        displayRatingDialog: displayRatingDialog,
        ratingId: ratingId
    };
}

export function setOriginalDimension(dimension) {
    return {
        type: SET_ORIGINAL_DIMENSION,
        dimension: dimension
    };
}

export function setChatType(chatType) {
    return {
        type: SET_CHAT_TYPE,
        chatType: chatType
    };
}