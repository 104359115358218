import i18next from 'i18next';
import spanish from './locales/es.json';
import english from './locales/en.json';

i18next.init({
  lng: 'es',
  debug: false,
  interpolation: {
    escapeValue: false
  },
  resources: {
    es: {
      translation: spanish
    },
    en: {
      translation: english
    },
  }
});

export default i18next;