import React, { useState, useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';

// Material UI
import { withStyles } from '@mui/styles';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import axiosInstance from '../../constants/Api';
import SearchBox from './SearchBox';
import ArticleCard from './ArticleCard';
import styles from '../styles';
import i18n from '../../i18n';
import LoadingComponent from '../LoadingComponent';

function KnowledgeBase({ classes }) {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const articlesReqRef = useRef(null);

  const getArticles = () => {
    articlesReqRef && articlesReqRef.current && articlesReqRef.current();
    setLoading(true);
    const { CancelToken } = axios;
    axiosInstance.get('/integracion-sagicc-externo/knowledge-base/getArticles',
      {
        params: { filtros: { searchText } },
        cancelToken: new CancelToken(((c) => {
          articlesReqRef.current = c;
        }))
      }
    ).then(function (response) {
      if (response.data.statusResult === true && response.data.status === 'OK') {
        setData(response.data.result);
      }
      setLoading(false);
    }).catch(function (error) {
      setLoading(false);
      console.log(error);
    });
  }

  useEffect(() => {
    getArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <CardContent className={classes.kbCardContent}>
      {loading && searchText === '' ? (
        <LoadingComponent />
      )
        : (
          <React.Fragment>
            <SearchBox
              searchText={searchText}
              handleSearchText={(newSearchText) => { setSearchText(newSearchText); }}
              loading={loading}
            />
            <Typography variant="subtitle2" gutterBottom className={classes.kbCardContentTitle}>
              {searchText === '' ? i18n.t('knowledge_base.recent_articles') : i18n.t('knowledge_base.articles_found')}
            </Typography>
            <div className={classes.kbCardContentInnerDiv}>
              <Scrollbars
                style={{ height: '100%' }}
                renderTrackHorizontal={props => <div {...props} style={{ display: 'none', overflowX: 'hidden' }} />}>
                <List sx={{ padding: '10px' }} component="nav">
                  {data.length > 0 ? (
                    <React.Fragment>
                      {data.map((obj) => (
                        <ArticleCard article={obj} />
                      ))}
                    </React.Fragment>
                  ) : (
                    <Typography variant="overline" component="div" textAlign="center">
                      {i18n.t('knowledge_base.no_results_found')}
                    </Typography>
                  )}
                </List>
              </Scrollbars>
            </div>
          </React.Fragment>
        )}
    </ CardContent>
  );
}

export default withStyles(styles)(KnowledgeBase);
