import {
    SET_INITIAL_FORM_DATA,
    SET_CHAT_CONFIG,
    SET_CHAT_ID,
    SET_AGENTS_AVAILABLE,
    ADD_MESSAGES,
    HANDLE_END_CHAT_DIALOG,
    EXIT_CHAT_SUCCESS,
    HANDLE_CHAT_END_LOADING,
    HANDLE_SOUND,
    HANDLE_TRANSCRIPTION,
    HANDLE_NOTIFICATION,
    HANDLE_RATING_DIALOG,
    SET_ORIGINAL_DIMENSION,
    SET_CHAT_TYPE
} from '../constants/ActionTypes';

const estadoInicial = {
    chatToken: window.sagicc_chat_config ? window.sagicc_chat_config.chat_token : null,
    chatId: localStorage.getItem('SAGICC_CHAT_ID') || null,
    agentsAvailable: false,
    initialForm: {
        tipo_documento: window.sagicc_chat_config && window.sagicc_chat_config.client_tipo_documento ? window.sagicc_chat_config.tipo_documento : (localStorage.getItem('SAGICC_CLIENT_TIPO_DOCUMENTO') || ''),
        nro_documento: window.sagicc_chat_config && window.sagicc_chat_config.client_nro_documento ? window.sagicc_chat_config.client_nro_documento : (localStorage.getItem('SAGICC_CLIENT_NRO_DOCUMENTO') || ''),
        first_name: window.sagicc_chat_config && window.sagicc_chat_config.client_first_name ? window.sagicc_chat_config.client_first_name : (localStorage.getItem('SAGICC_CLIENT_FIRST_NAME') || ''),
        last_name: window.sagicc_chat_config && window.sagicc_chat_config.client_last_name ? window.sagicc_chat_config.client_last_name : (localStorage.getItem('SAGICC_CLIENT_LAST_NAME') || ''),
        email: window.sagicc_chat_config && window.sagicc_chat_config.client_email ? window.sagicc_chat_config.client_email : (localStorage.getItem('SAGICC_CLIENT_EMAIL') || ''),
        phone: window.sagicc_chat_config && window.sagicc_chat_config.client_phone ? window.sagicc_chat_config.client_phone : (localStorage.getItem('SAGICC_CLIENT_PHONE') || ''),
        message: window.sagicc_chat_config && window.sagicc_chat_config.auto_initialize !== undefined &&
            window.sagicc_chat_config.client_first_name !== undefined && window.sagicc_chat_config.client_last_name !== undefined &&
            window.sagicc_chat_config.client_email !== undefined && (window.sagicc_chat_config.auto_initialize === true || window.sagicc_chat_config.auto_initialize === 'true') &&
            window.sagicc_chat_config.client_first_name !== '' && window.sagicc_chat_config.client_last_name !== '' &&
            window.sagicc_chat_config.client_email !== '' ? 'Comenzar' : (window.sagicc_chat_config ? window.sagicc_chat_config.client_message : '')
    },
    chatConfig: {
        title: '',
        close_case: true,
        require_id: false,
        primary_color: '#1C344C',
        accent_color: '#D63344',
        enable_sound: true,
        require_email: true,
        require_phone: false,
        widget_position: 'bottom-right',
        enable_attachments: true,
        terms_conditions_link: '',
        icon_image: '',
        enable_rating: true
    },
    messages: [],
    displayEndChatDialog: false,
    displayChatEndLoading: false,
    sound: localStorage.getItem('SAGICC_CHAT_SOUND') || true,
    transcription: localStorage.getItem('SAGICC_CHAT_TRANSCRIPTION') || true,
    displayNotification: false,
    displayRatingDialog: false,
    ratingId: null,
    notificationMessage: '',
    chatType: window.sagicc_chat_config ? window.sagicc_chat_config.chat_type : 'widget',
    containerId: 'sagicc-chat-' + Math.random(),
    originalDimension: {
        width: 0,
        height: 0
    },
    widgetToken: window.sagicc_omni_widget_config ? window.sagicc_omni_widget_config.widget_token : null
}

const chat = (state = estadoInicial, action) => {
    switch (action.type) {
        case SET_INITIAL_FORM_DATA:
            return {
                ...state,
                initialForm: action.data
            }
        case SET_CHAT_CONFIG:
            return {
                ...state,
                chatConfig: action.data
            }
        case SET_CHAT_ID:
            return {
                ...state,
                chatId: action.chatId
            }
        case SET_AGENTS_AVAILABLE:
            return {
                ...state,
                agentsAvailable: action.agentsAvailable
            }
        case ADD_MESSAGES:
            let messages = state.messages;
            let newMessages = [...messages, ...action.messages];

            return {
                ...state,
                messages: newMessages
            }
        case HANDLE_END_CHAT_DIALOG:
            return {
                ...state,
                displayEndChatDialog: action.displayEndChatDialog
            }
        case EXIT_CHAT_SUCCESS:
            return {
                ...state,
                displayEndChatDialog: false,
                displayChatEndLoading: false,
                chatId: null,
                agentsAvailable: false,
                messages: [],
                initialForm: {
                    tipo_documento: window.sagicc_chat_config ? window.sagicc_chat_config.client_tipo_documento : (localStorage.getItem('SAGICC_CLIENT_TIPO_DOCUMENTO') || ''),
                    nro_documento: window.sagicc_chat_config ? window.sagicc_chat_config.client_nro_documento : (localStorage.getItem('SAGICC_CLIENT_NRO_DOCUMENTO') || ''),
                    first_name: window.sagicc_chat_config ? window.sagicc_chat_config.client_first_name : (localStorage.getItem('SAGICC_CLIENT_FIRST_NAME') || ''),
                    last_name: window.sagicc_chat_config ? window.sagicc_chat_config.client_last_name : (localStorage.getItem('SAGICC_CLIENT_LAST_NAME') || ''),
                    email: window.sagicc_chat_config ? window.sagicc_chat_config.client_email : (localStorage.getItem('SAGICC_CLIENT_EMAIL') || ''),
                    phone: window.sagicc_chat_config ? window.sagicc_chat_config.client_phone : (localStorage.getItem('SAGICC_CLIENT_PHONE') || ''),
                    message: window.sagicc_chat_config && window.sagicc_chat_config.auto_initialize !== undefined &&
                        window.sagicc_chat_config.client_first_name !== undefined &&
                        window.sagicc_chat_config.client_last_name !== undefined &&
                        window.sagicc_chat_config.client_email !== undefined &&
                        (window.sagicc_chat_config.auto_initialize === true || window.sagicc_chat_config.auto_initialize === 'true') &&
                        window.sagicc_chat_config.client_first_name !== '' &&
                        window.sagicc_chat_config.client_last_name !== '' &&
                        window.sagicc_chat_config.client_email !== '' ? 'Comenzar' : ''
                }
            }
        case HANDLE_CHAT_END_LOADING:
            return {
                ...state,
                displayChatEndLoading: action.displayChatEndLoading
            }
        case HANDLE_SOUND:
            localStorage.setItem('SAGICC_CHAT_SOUND', !state.sound);
            return {
                ...state,
                sound: !state.sound
            }
        case HANDLE_TRANSCRIPTION:
            localStorage.setItem('SAGICC_CHAT_TRANSCRIPTION', !state.transcription);
            return {
                ...state,
                transcription: !state.transcription
            }
        case HANDLE_NOTIFICATION:
            return {
                ...state,
                displayNotification: action.displayNotification,
                notificationMessage: action.notificationMessage
            }
        case HANDLE_RATING_DIALOG:
            return {
                ...state,
                displayRatingDialog: action.displayRatingDialog,
                ratingId: action.ratingId
            }
        case SET_ORIGINAL_DIMENSION:
            return {
                ...state,
                originalDimension: action.dimension
            }
        case SET_CHAT_TYPE:
            return {
                ...state,
                chatType: action.chatType
            }
        default:
            return state;
    }
}

export default chat;
