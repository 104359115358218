import React from 'react';

// MUI
import { withStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Footer from './Footer';

// Other Components
import styles from '../styles';

function OmniList({ config, toggleWidget, classes }) {
  const openLink = (url, open) => {
    if (url && url !== '') {
      if (!open) {
        window.open(url, '_blank');
      } else {
        window.open(url, 'sagicc-chat-win', 'width=400,height=500,location=no,menubar=no,resizable=no,scrollbars=yes,status=no,titlebar=no,toolbar=no');
      }
    }
  };

  const getOmniWidgetItems = () => {
    let items = [];
    if (config && config.omni_widget_channels && config.omni_widget_channels.length > 0) {
      items = config.omni_widget_channels.map((obj) => {
        let itemIcon = null;
        let itemClass = null;
        let openWindow = false;

        switch (obj.channel_type) {
          case 'whatsapp':
            itemIcon = 'fab fa-whatsapp';
            itemClass = classes.omniWidgetAvatarWhatsApp;
            break;
          case 'facebook':
            itemIcon = 'fab fa-facebook-messenger';
            itemClass = classes.omniWidgetAvatarFacebookMessenger;
            break;
          case 'telegram':
            itemIcon = 'fab fa-telegram';
            itemClass = classes.omniWidgetAvatarTelegram;
            break;
          case 'instagram':
            itemIcon = 'fab fa-instagram';
            itemClass = classes.omniWidgetAvatarInstagram;
            break;
          case 'chat':
            itemIcon = 'fas fa-comment';
            openWindow = true;
            break;
          case 'website':
            itemIcon = 'fas fa-clipboard-list';
            break;
          case 'email':
            itemIcon = 'fas fa-envelope';
            break;
          case 'twitter':
            itemIcon = 'fab fa-twitter';
            itemClass = classes.omniWidgetAvatarTwitter;
            break;
          default:
            itemIcon = obj.channel_icon || null;
            break;
        }

        return (
          <ListItem
            button
            className={`${itemClass} ${classes.omniWidgetListItem}`}
            key={'list-item-' + obj.channel_id}
            onClick={() => { openLink(obj.url, openWindow); }}
            sx={obj.channel_color && obj.channel_color !== '' && {
              borderColor: `${obj.channel_color} !important`,
              color: `${obj.channel_color} !important`,
              '&:hover': {
                backgroundColor: `${obj.channel_color} !important`,
                color: '#FFFFFF !important',
              }
            }}>
            <ListItemAvatar sx={{ minWidth: '36px' }}>
              <Icon className={`${itemIcon} ${classes.omniWidgetIcon}`} />
            </ListItemAvatar>
            <ListItemText primary={obj.channel_label && obj.channel_label !== ''
              ? obj.channel_label : (
                obj.channel_name.length > 22 ? obj.channel_name.substring(0, 22) + "..." : obj.channel_name
              )} />
          </ListItem>
        );
      });
    }

    return (
      <List component="nav">
        {items}
      </List>
    );
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        className={`${classes.omniWidgetAppBar} ${config.omni_widget_position ? config.omni_widget_position : 'bottom-right'} sowc-header-appbar`}
      >
        <Toolbar className={`${classes.toolbar} sowc-header-toolbar`}>
          {config && config.omni_widget_business_image && config.omni_widget_business_image !== '' && (
            <Avatar src={config.omni_widget_business_image} className={classes.appBarAvatar} />
          )}
          <Typography variant="h6" className={`${classes.appBarTitle} sowc-header-h6`}>
            {config && config.omni_widget_title ? config.omni_widget_title : ''}
          </Typography>
          <IconButton
            color="inherit"
            onClick={toggleWidget}
            className={`sowc-header-icon-button`}
            size="large">
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Paper elevation={1} className={classes.sagiccOmniWidgetInner}>
        <Typography variant="body2" gutterBottom sx={{ marginBottom: '10px' }}>
          {config && config.omni_widget_subtitle ? config.omni_widget_subtitle : ''}
        </Typography>
        {getOmniWidgetItems()}
      </Paper>
      <Footer />
    </React.Fragment>
  );
}

export default withStyles(styles)(OmniList);
