import React, { Suspense } from 'react';

// Material UI
import { withStyles } from '@mui/styles';

// Other Components
import styles from '../styles';
import LoadingComponent from '../LoadingComponent';
import Buttons from './Buttons';
import List from './List';
import OmniIcon from './OmniIcon';

function Inner({ omniConfig, displayWidget, toggleWidget, state, classes }) {
  const duration = 200;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    display: 'none'
  }

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1, display: 'flex', flexDirection: 'column' }
  };

  return (
    <div
      id="sagicc-omni-widget"
      className={`${classes.sagiccOmniWidgetDiv} sowc-${omniConfig.omni_widget_position ? omniConfig.omni_widget_position : 'bottom-right'} sowc-${omniConfig.omni_widget_style} sowc-main-card`}
      style={Object.assign({ padding: 0, boxShadow: 'none' }, defaultStyle, transitionStyles[state])}>
      <Suspense fallback={<LoadingComponent />}>
        <React.Fragment>
          {
            omniConfig.omni_widget_style && omniConfig.omni_widget_style === 'omni-widget-floating' && (
              <Buttons config={omniConfig} toggleWidget={toggleWidget} />
            )
          }
          {
            omniConfig.omni_widget_style && omniConfig.omni_widget_style === 'omni-widget-list' && (
              <List config={omniConfig} toggleWidget={toggleWidget} />
            )
          }
          <OmniIcon omniConfig={omniConfig} displayWidget={displayWidget} toggleWidget={toggleWidget} />
        </React.Fragment>
      </Suspense>
    </div>
  );
}

export default withStyles(styles)(Inner);
