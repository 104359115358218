import {
    /**
     * Initialize Video
     */
    VIDEO_CHAT_INITIALIZING_ROOM,
    VIDEO_CHAT_ENTER_ROOM,
    VIDEO_CHAT_EXIT_ROOM,
    /**
     * MEDIA AVAILABILITY
     */
    VIDEO_CHAT_TOGGLE_MIC,
    VIDEO_CHAT_TOGGLE_SPEAKER,
    VIDEO_CHAT_TOGGLE_VIDEO,
    /**
     * MEDIA SETTINGS
     */
    VIDEO_CHAT_TOGGLE_SETTINGS,
    VIDEO_CHAT_SAVE_SETTINGS,
    /**
     *  GENERAL
     */
    VIDEO_CHAT_TOGGLE_WINDOW,
    VIDEO_CHAT_TOGGLE_VIEW,

    VIDEO_CHAT_SET_ROOM,
    VIDEO_CHAT_SET_OV,
    VIDEO_CHAT_SET_CONVERSATION,
    VIDEO_CHAT_TOGGLE_CHAT,

    VIDEO_CHAT_SET_SESSION,
    VIDEO_CHAT_SET_LOCAL_USER,
    VIDEO_CHAT_SET_REMOTE_USER,
    FORCE_STATE
} from '../constants/ActionTypes';

const estadoInicial = {
    fullscreen: false,
    mic: '',
    camera: '',
    speaker: '',
    mic_enabled: true,
    speaker_enabled: true,
    video_enabled: true,
    settingsOpened: false,
    settingsTarget: null,
    status: 0,
    opened: false,
    conversation: null,
    room: null,
    session_id: null,
    chatOpened: false,
    OV: null,
    local_user: null,
    remote_user: null
}


const videoChat = (state = estadoInicial, action) => {
    //console.log(action)
    switch (action.type) {
        case VIDEO_CHAT_TOGGLE_VIEW:
            return {
                ...state,
                fullscreen: !state.fullscreen
            }
        case VIDEO_CHAT_INITIALIZING_ROOM:
            return {
                ...state,
                status: 0
            }
        case VIDEO_CHAT_ENTER_ROOM:
            return {
                ...state,
                status: 1,
                conversation: action.conversation
            }
        case VIDEO_CHAT_EXIT_ROOM:
            return {
                ...state,
                status: -1,
                conversation: null
            }
        case VIDEO_CHAT_TOGGLE_MIC:
            return {
                ...state,
                mic_enabled: !state.mic_enabled
            }
        case VIDEO_CHAT_TOGGLE_SPEAKER:
            return {
                ...state,
                speaker_enabled: !state.speaker_enabled
            }
        case VIDEO_CHAT_TOGGLE_VIDEO:
            return {
                ...state,
                video_enabled: !state.video_enabled
            }
        case VIDEO_CHAT_TOGGLE_SETTINGS:
            return {
                ...state,
                settingsTarget: action.settingsTarget,
                settingsOpened: !state.settingsOpened
            }
        case VIDEO_CHAT_SAVE_SETTINGS:
            return {
                ...state,
                mic: action.mic,
                camera: action.camera,
                speaker: action.speaker
            }
        case VIDEO_CHAT_TOGGLE_WINDOW:
            return {
                ...state,
                opened: !state.opened,
                room: state.opened ? null : state.room,
                session_id: state.opened ? null : state.session_id,
                conversation: state.opened ? null : state.conversation,

            }
        case VIDEO_CHAT_SET_OV:
            return {
                ...state,
                OV: action.OV
            }
        case VIDEO_CHAT_SET_ROOM:
            return {
                ...state,
                room: action.room
            }
        case VIDEO_CHAT_SET_CONVERSATION:
            return {
                ...state,
                conversation: action.conversation
            }
        case VIDEO_CHAT_SET_SESSION:
            return {
                ...state,
                session_id: action.session_id
            }
        case VIDEO_CHAT_SET_LOCAL_USER:
            return {
                ...state,
                local_user: action.local_user
            }
        case VIDEO_CHAT_SET_REMOTE_USER:
            return {
                ...state,
                remote_user: action.remote_user
            }
        case VIDEO_CHAT_TOGGLE_CHAT:
            return {
                ...state,
                chatOpened: !state.chatOpened
            }
        case FORCE_STATE:
            return {
                ...state,
                ...action.state
            }
        default:
            return state;
    }
}

export default videoChat;