import React from 'react';
import styles from '../styles';

// MUI
import { withStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import Icon from '@mui/material/Icon';

function OmniIcon({ omniConfig, displayWidget, toggleWidget, classes }) {
  let chatWidgetIcon = null;

  switch (omniConfig.omni_widget_icon) {
    case 'floating-button':
      chatWidgetIcon = (
        <Fab
          color="primary"
          onClick={toggleWidget}
          className={`${classes.fab} sowc-main-fab`}
        >
          <Icon>{displayWidget ? 'close' : 'chat'}</Icon>
        </Fab>
      );
      break;
    case 'custom-image':
      if (omniConfig.omni_widget_icon_image && omniConfig.omni_widget_icon_image !== '') {
        if (displayWidget) {
          chatWidgetIcon = (
            <Fab
              color="primary"
              onClick={toggleWidget}
              className={`${classes.fab} sowc-main-fab`}
            >
              <Icon>close</Icon>
            </Fab>
          );
        } else {
          chatWidgetIcon = (
            <img
              src={omniConfig.omni_widget_icon_image} alt={omniConfig.title}
              onClick={toggleWidget}
              className={`${omniConfig.omni_widget_position ? omniConfig.omni_widget_position : 'bottom-right'} ${classes.customImage} sowc-main-paper-img`}
            />
          );
        }
      }
      else {
        chatWidgetIcon = (
          <Fab
            color="primary"
            onClick={toggleWidget}
            className={`${classes.fab} sowc-main-fab`}
          >
            <Icon>chat</Icon>
          </Fab>
        );
      }
      break;
    default:
      chatWidgetIcon = (
        <Fab
          color="primary"
          onClick={toggleWidget}
          className={`${classes.fab} sowc-main-fab`}
        >
          <Icon>chat</Icon>
        </Fab>
      );
  }

  if (displayWidget === true) {
    return null;
  }

  return chatWidgetIcon;
}

export default withStyles(styles)(OmniIcon);

